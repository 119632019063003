@font-face {
  font-family: "Visby";
  src: local('Visby'),
  url('./fonts/Visby-CF/Fontspring-DEMO-visbycf-regular.otf') format('otf'),
}

@import url('https://fonts.cdnfonts.com/css/visby');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer{
  background: url('/public/images/Frame\ 424.png');
  background-size: cover;
  background-position: center;
}

.header{
  background: url('/public/images/Frame\ 424.png');
  background-size: cover;
  background-position: center;
}

.AuthBackground{
  background: url('/public/images/Frame\ 424.png');
  background-size: cover;
  background-position: center;
}

.blueBackground{
  background: url('/public/images/Frame\ 424.png');
  background-size: cover;
  background-position: center;
}

.whiteBackground{
  background: url('/public/images/frame.png');
  background-size: cover;
  background-position: center;
}
.fontFamily1{
  font-family: 'Poppins', sans-serif!important;
  font-weight: auto;
}

.fontFamily2{
  font-family: 'Visby'!important;
  font-weight: auto;
}

.footerLink{
  font-family: 'Poppins', sans-serif!important;
  font-weight: auto;
  color:#333d47!important;
  font-size: 14px!important;
  cursor: pointer;
  border: none !important;
  padding:8px 0!important;
}

.header-menu{
  font-family: 'Poppins', sans-serif!important;
  color:#333d47;
  font-weight: bold!important;
  font-size: 16px!important;
}

.ant-menu-item::after {
display: none!important;
}

.header-menu .ant-menu-item{
  padding:0px 20px!important;
  height: auto!important;
}

.header-menu .ant-menu-title-content:after{
  color:#333d47!important;
}

.header-menu .ant-menu-item *{
  color:#333d47!important;
}
.header-menu .ant-menu-item-selected{
  color:#333d47!important;
}


.filterDropdown {
  border: none;
  border-radius: 4px;
  max-width: 300px;
}

.filterDropdownHeader {
  padding: 0rem 1rem;
}

.filterDropdownBody {
  padding: 1rem 1rem;
}

.filterDropdownBody {
  padding: 1rem 1rem;
}


.filterInput {
  width: 100% !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px;
  margin-bottom: 10px;
}

.disabledTab{
  opacity: 0.5!important;
  cursor:not-allowed!important;
  background-color: white!important;
  color:black!important 
}


.disabledTab *{ 
  color:black!important
}

.mainLabel {
  font-size: 15px;
  color: #333;
  font-weight: 600;
  word-break: break-word;
  margin:0
}

.header-menu-mobile{
  font-family: 'Poppins', sans-serif!important;
  color:white;
  font-size: 16px!important;
}

.header-menu-mobile .ant-menu-item{
  padding:10px 20px!important;
  height: auto!important;
}

.ant-menu-horizontal{
  border-bottom: 0!important;
}

.authFormBox{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 41px 0px 0px 41px;
background: linear-gradient(135deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.00) 100%);
}
.heroSection{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ant-input-number-handler-wrap{
  display: none!important;
}

.flex{
  display:flex;
  justify-content:center;
  align-items: center;
}

.coachCard{
  background-size: cover;
  box-shadow: 1px 1px 4px 1px #fffbfb00;
}

.coachCard:hover{
  box-shadow:auto;
}

.coachCard2{
  background: url('/public/images/2.png');
  background-size: cover;
}
.coachCard3{
  background: url('/public/images/3.png');
  background-size: cover;
}
.coachCard4{
  background: url('/public/images/4.png');
  background-size: cover;
}
.coachCard5{
  background: url('/public/images/5.png');
  background-size: cover;
}
.authImageBox{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.loginForm .ant-form-item-label *{
  font-family: 'Visby', sans-serif!important;
  color:#333d47!important;
  font-weight: 450!important;
  font-size: 14px!important;
}

.loginForm .ant-form-item-required::before{
  display: none!important;
}


.contactForm .ant-form-item-label *{
  font-family: 'Visby', sans-serif!important;
  color:grey!important;
  font-weight: 600!important;
  font-size: 14px!important;
}

.contactForm .ant-form-item-required::before{
  display: none!important;
}
.AuthFormInput{
  border-radius: 10px!important;
background: rgba(255, 255, 255, 0.65)!important;
box-shadow: 1px 1px 4px 1px #dadada ;
color:#333d47!important;
height: 49px!important;
border:none
}
.loginButton{
  min-width: 157px;
height: 46px;
border-radius: 8px!important;
background: #00a79d!important;
font-family: 'Visby', sans-serif!important;
color:white!important;
font-weight: 550!important;
}

.upcomingButton{
  min-width: 250px;
height: 50px;
border-radius: 8px!important;
background: #FFEBF6!important;
font-family: 'Visby', sans-serif!important;
color:black!important;
font-weight: normal!important;
}


.completedButton{
  min-width: 250px;
  height: 50px;
  border-radius: 8px!important;
  background: #E7FAFF!important;
  font-family: 'Visby', sans-serif!important;
  color:black!important;
  font-weight: normal!important;
}


.messageButton{
  min-width: 250px;
  height: 50px;
  border-radius: 8px!important;
  background: #D7FDC3!important;
  font-family: 'Visby', sans-serif!important;
  color:black!important;
  font-weight: normal!important;
}
.courseButton{
  min-width: 250px;
  height: 50px;
  border-radius: 8px!important;
  background: #BBF2F9!important;
  font-family: 'Visby', sans-serif!important;
  color:black!important;
  font-weight: normal!important;
}
.scheduleButton{  min-width: 250px;
  height: 50px;
  border-radius: 8px!important;
  background: #ECE7FF!important;
  font-family: 'Visby', sans-serif!important;
  color:black!important;
  font-weight: normal!important;}

.serviceButton{  min-width: 250px;
  height: 50px;
  border-radius: 8px!important;
  background: #BBF2F9!important;
  font-family: 'Visby', sans-serif!important;
  color:black!important;
  font-weight: normal!important;}

.ratesButton{  min-width: 250px;
  height: 50px;
  border-radius: 8px!important;
  background: #FDC3C3!important;
  font-family: 'Visby', sans-serif!important;
  color:black!important;
  font-weight: normal!important;}

.reviewButton{  min-width: 250px;
  height: 50px;
  border-radius: 8px!important;
  background: #F4F9BB!important;
  font-family: 'Visby', sans-serif!important;
  color:black!important;
  font-weight: normal!important;}

.tutorButton{
  min-width: 200px;
  height: 46px;
  border-radius: 8px!important;
  background: #BBF2F9!important;
  font-family: 'Visby', sans-serif!important;
  color:black!important;
  font-weight: 550!important;
}


.heroButton{
  width: 150px;
  height: 60px;
  border-radius: 50px!important;
  background: #2584c6!important;
  font-family: 'Visby', sans-serif!important;
  color:white!important;
  font-weight: 550!important;
}
.AuthFormInput .ant-input { 
font-size: 14px!important;
background: transparent!important;
color:#333d47!important;
}

.AuthFormInput .ant-input-suffix *{
  color:#333d47!important;
}

.AuthFormInput .ant-input::placeholder{
  color : #333d47!important;
}
.heroSerch .ant-input::placeholder{
  color : grey!important;
  font-weight: 400!important;
}
.heroSerch  .ant-input-affix-wrapper-lg{
    padding: 5px 5px 5px 10px!important;
}
.heroSerch {
  border-radius: 50px;
  overflow: hidden;
}


.ContactFormInput{
  border-radius: 10px!important;
background: #efefef9a!important;
color:grey!important;
height: 49px!important;
border:none
}

.ContactFormTextField{
  border-radius: 10px!important;
  background: #efefef9a!important;
  color:grey!important;
  border:none
}

.signupFormInput{
  border-radius: 10px!important;
background: #efefef9a!important;
border:none;
height: 49px!important;
font-family: 'Poppins', sans-serif!important;
font-weight: auto;
font-size: 14px!important;
}
.letterSpacing *{
letter-spacing: 10px;
}
.ContactFormInput2{
  border-radius: 10px!important;
  background: #efefef9a!important;
  color:grey!important;
  height: auto!important;
  border:none;
  padding:5px 10px!important;
  height: 49px!important;
}


.ContactFormInput .ant-input { 
  
  font-family: 'Poppins', sans-serif!important;
  font-weight: auto;
  background: transparent!important;
  color:grey!important;
  }
  
  .ContactFormInput .ant-input-suffix *{
    color:grey!important;
  }
  
  .ContactFormInput .ant-input::placeholder{
    color : grey!important;
  }



  .signupFormInput .ant-input { 
    font-size: 14px!important;
    background: transparent!important;
    color:grey!important;
    }
    
    .signupFormInput .ant-input-suffix *{
      color:grey!important;
    }
    
    .signupFormInput .ant-input::placeholder{
      color : grey!important;
    }


  .searchBar .ant-btn{
    background-color: #00a79d!important;

  }

.rangeSlider  .ant-slider-track {
  background-color: #00a79d!important;
}

.rangeSlider  .ant-slider-handle::after{
  box-shadow: 0 0 0 2px #00a79d!important;
}
.avaliblityGroup .ant-checkbox * {

  border-radius: 20px!important;
}

.avaliblityGroup .ant-checkbox-checked *{
  background-color: #00a79d!important;
  border-color: #00a79d!important;
  border-radius: 20px!important;
}
.ant-checkbox-inner *{
  background-color:green!important;
}

.swal-text{
  text-align: center!important;
  width: 80%!important;
}

.swal-footer{
  display:flex!important;
  justify-content: center!important;
}

.swal-button{
  background-color: #00a79d;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill{
  -webkit-text-fill-color: #333d47 !important;
}

.signupFormInput:-webkit-autofill {
  -webkit-text-fill-color: black !important;
}

.StyledModal .ant-modal-footer {
 display: flex;
 justify-content: center;
}


.StyledModal .ant-modal-content{
 height: 300px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.StyledModal .ant-btn-primary{
  background-color: #00a79d!important;
  border-radius: 10px!important;
  border-color: #00a79d!important;
}

.StyledModal .ant-btn-default{
  border-radius: 10px!important;
  /* border-color: #00a79d!important;
  color: #00a79d!important; */
}


.tutorCard{
  border-radius: 10px;
background: #FFF;
padding:0!important;
box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.16)!important;
}

.tutorDetailCard{
  border-radius: 10px;
background: #FFF;
padding:0!important;
box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.07)!important;
}


.tutorDetailCard .ant-card-body{
  padding: 0px;
}

.tutorCard .ant-card-body{
  padding: 20px;
}


.categoryTab .ant-tabs-tab{
  font-size: 20px;

  padding:10px 20px;
  font-family: 'Poppins', sans-serif!important;
}

.categoryTab  .ant-tabs-tab-active *{
color:#00a79d!important;
}


.categoryTab  .ant-tabs-ink-bar{
  background:#00a79d!important;
  }


  .learningImage1{
    width:100%;
    height:50vh;
    border-radius: 30px;
  /* background: url(/public/images/learning.png); */
  background: url(/public/images/Home-Page.jpg);
  background-position: center;
  background-size: cover;
  }


.authImage1{
  width:100%;
  height:65vh;
  border-radius: 53px;
background: url(/public/images/Rectangle_1.png);
background-position: center;
background-size: cover;
}
.authImage2{
  width:100%;
  height:65vh;
  border-radius: 53px;
background: url(/public/images/Rectangle_2.png);
background-size: cover;
background-position: center;
}
.authImage3{
  width:100%;
  height:65vh;
  border-radius: 53px;
background: url(/public/images/Rectangle_3.png);
background-size: cover;
background-position: center;
}

.joinCard1{
  background: url(/public/images/green.png);
background-size: cover;
background-position: center;
}

.joinCard2{
  /* background: url(/public/images/blue.png); */
  background: url(/public/images/Register.jpg);
background-size: cover;
background-position: center;
}


.homeVideo{
  width: 100%;
  height: 100%;
  object-fit: fill;
  padding: 0;
  transform: scale(1.1);
  position: relative;
  border-radius: 2.6041666666666665vw;
  overflow: hidden;
}

.hover:hover{
  color:#00a79d!important
}

.codeInput{
  width: 50px;
  z-index: 5;
  border: none!important;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.65)!important;
box-shadow: 1px 1px 4px 1px #dadada ;
  padding: 5px;
  height:50px;
  line-height: 20px;
  text-align: center;
  font-size: 20px;
  color:#333d47;
}
.codeInput:focus{
 outline:none;
}

.animation-blue{
  position: absolute;
  bottom: 5%;
  right: 0;
}
.animation-blue .cirlce-small {
  position: relative;
  right: 20px;
  bottom:100px;
  width:50px;

  transition: all 0.3s ease-in-out;
}


 .animation-blue .circle {
  mix-blend-mode: overlay;
  position: relative;
  width: 150px;
  bottom:80px;
  
  transition: all 0.3s ease-in-out;
}
 .animation-blue .kaju {
  position: relative;
bottom:20;
right: 150px;
  width: 70px;
  transition: all 0.3s ease-in-out;
}


.chatButton{
  background-color: #00a79d!important;
}

.infoBox{
  border-radius: 8px;
background: #FFF;
box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.07)!important;
}

.chatCard{
  border-radius: 8px;
background: #FFF;
box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.07)!important;
}

.chatCard .ant-card-body{
  padding:0!important;
}


.aboutCard1{
  /* background: url(/public/images/aboutUs.png); */
  background: url(/public/images/aboutUs.jpg);
background-size: cover;
background-position: center;
border-radius: 26px!important;
}

.contactCard1{
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.07)!important;
}


.contactCard2{
  border-radius: 8px;
background: #FFF;
box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.16)!important;
}

.smallTitle{
  font-size: 55px!important;
}

.smallText{
  font-size: 20px;
}
.vdo{
  padding: 0px 50px;
}

@media only screen and (max-width: 600px) {
  .hide-on-phone {
   display: none!important;
  }
  .authFormBox{
    border-radius: 0;
  }
  .smallTitle{
    font-size: 40px!important;
  }
  .smallTitle2{
    font-size: 30px!important;
  }
  .smallText{
    font-size: 16px;
  }
  .heroButton{
    height: 40px!important;
    width: 120px;
  }

  .vdo{
    top: 50px!important;
    padding: 0px 10px;
  }
}



.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
 
/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(56, 87, 144); 
  border-radius: 10px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #213758; 
}
.StripeElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px!important;
  background: #efefef9a!important;
  padding: 10px;
  color:grey!important;
  font-family: 'Poppins', sans-serif!important;
  font-weight: auto;
  color:grey!important;
  height: 49px!important;
  border:none;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.subjectsGroup label{
  width: 20%;
  min-width: 200px;
  align-items: center;
}



/* //////////////////////// */


.future-cards {
  background-color: #fff;
  border-radius: 20px;
  padding: 30px;
  border: 2px solid #00a79d;
  min-height: 100%;
}

.future-cards h1 {
  text-align: center !important;
}

.future-cards span {
  text-align: center !important;
  display: inline;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  /* Important for long words! */
}

.future-cards button {
  border: 2px solid #00a79d;
}


.left-right-box {
  margin: 20px 0;
}


.left-text-box {
  padding: 50px;
}
